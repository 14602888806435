import { Injectable, EventEmitter, Injector, NgZone } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Observer,
  of,
  Subject,
  Subscription,
  distinctUntilChanged,
  switchMap,
  tap,
} from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { AkitaRouterQuery } from '@app/akita/router/state/router.query';
import { TabbyInstance } from '../models/tabby-instance.model';

@Injectable({ providedIn: 'root' })
export class TabbyScriptTag {
  private readonly document: Document | null;
  private readonly src: string = 'https://checkout.tabby.ai/integration.js';

  private readonly tokenChanged: EventEmitter<TabbyInstance>;
  private addTabbyTokenSubject: Subject<TabbyInstance | null> | null;
  private readonly tabbyInstanceChanged: EventEmitter<TabbyInstance | null>;

  private readonly tabbyInstanceSubject: BehaviorSubject<TabbyInstance | null>;

  private readonly subscriptions: Subscription;

  constructor(
    private readonly zone: NgZone,
    private readonly injector: Injector,
    private readonly akitaRouterQuery: AkitaRouterQuery
  ) {
    this.subscriptions = new Subscription();

    if (this.akitaRouterQuery.isBrowser) {
      try {
        this.document = this.injector.get<Document | null>(DOCUMENT, null);
      } catch (err) {
        this.document = null;
      }
    } else {
      this.document = null;
    }

    this.tokenChanged = new EventEmitter();
    this.tabbyInstanceChanged = new EventEmitter();
    this.addTabbyTokenSubject = null;
    this.tabbyInstanceSubject = new BehaviorSubject(null as any);
  }

  public stopSubscriptions(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  public get onTokenChange(): Observable<TabbyInstance | null> {
    return this.tokenChanged.asObservable().pipe(distinctUntilChanged());
  }
  public get instance(): TabbyInstance | null {
    return this.tabbyInstanceSubject.value;
  }

  public get instance$(): Observable<TabbyInstance | null> {
    return this.tabbyInstanceSubject.asObservable();
  }

  public promiseInstance(): Observable<TabbyInstance | null> {
    if (this.akitaRouterQuery.isBrowser) {
      const subscriptions = new Subscription();
      return new Observable((observer: Observer<TabbyInstance | null>) => {
        this.zone.runOutsideAngular(() => {
          subscriptions.add(
            this.injectIntoHead()
              .pipe(
                switchMap((tabbyInstance: TabbyInstance | null) => {
                  if (tabbyInstance) {
                    this.zone.run(() => {
                      this.tabbyInstanceSubject.next(tabbyInstance);
                      observer.next(tabbyInstance);
                      observer.complete();
                    });
                    if (subscriptions) {
                      subscriptions.unsubscribe();
                    }
                    return of(tabbyInstance);
                  } else {
                    return this.tabbyInstanceChanged.pipe(
                      tap(() => {
                        if (!tabbyInstance) {
                          const err: {
                            type: string;
                            code: string;
                            charge: string;
                            message: string;
                          } = {
                            type: 'invalid_request_error',
                            charge: '',
                            message: 'Tabby PublicKey NOT SET.',
                            code: 'TABBY_KEY_NOT_SET',
                          };
                          this.zone.run(() => {
                            observer.error(err);
                            observer.complete();
                          });
                          if (subscriptions) {
                            subscriptions.unsubscribe();
                          }
                        } else {
                          this.zone.run(() => {
                            this.tabbyInstanceSubject.next(tabbyInstance);
                            observer.next(tabbyInstance);
                            observer.complete();
                          });
                          if (subscriptions) {
                            subscriptions.unsubscribe();
                          }
                        }
                      })
                    );
                  }
                })
              )
              .subscribe({
                next: () => {},
                error: (err: unknown) => {
                  this.zone.run(() => {
                    observer.error(err);
                    observer.complete();
                  });
                  if (subscriptions) {
                    subscriptions.unsubscribe();
                  }
                },
              })
          );
        });
      });
    } else {
      return of(null);
    }
  }

  private injectIntoHead(): Observable<TabbyInstance | null> {
    if (this.akitaRouterQuery.isBrowser) {
      const subscriptions = new Subscription();
      return new Observable((observer: Observer<TabbyInstance | null>) => {
        if (this.addTabbyTokenSubject && !this.addTabbyTokenSubject.closed) {
          subscriptions.add(
            this.addTabbyTokenSubject.asObservable().subscribe({
              next: (instance: TabbyInstance | null) => {
                observer.next(instance);
                observer.complete();

                if (subscriptions) {
                  subscriptions.unsubscribe();
                }
              },
              error: () => {
                observer.next(null);
                observer.complete();

                if (subscriptions) {
                  subscriptions.unsubscribe();
                }
              },
            })
          );
        } else {
          const tabby = this.grabTabby();
          if (tabby) {
            observer.next(tabby);
            observer.complete();

            if (subscriptions) {
              subscriptions.unsubscribe();
            }
            return;
          }

          if (!this.document) {
            // Document not Available in Server Rendering
            observer.error('TABBY_DOCUMENT_NOT_AVAILABLE');
            observer.complete();

            if (subscriptions) {
              subscriptions.unsubscribe();
            }
            return;
          }

          const head = this.getTargetTagDropElement();
          if (head) {
            const script = (this.document as any).createElement('script');
            script.setAttribute('src', this.src);
            script.setAttribute('nonce', 'dad9ccf9905b305ed33d30c2d09db135');
            script.setAttribute('name', 'dynamic-tabby-script');
            script.setAttribute('type', 'text/javascript');

            script.addEventListener('load', () => {
              const tabbyInstance = this.grabTabby();
              if (this.addTabbyTokenSubject && !this.addTabbyTokenSubject.closed) {
                this.addTabbyTokenSubject.next(tabbyInstance);
                this.addTabbyTokenSubject.complete();
                this.addTabbyTokenSubject = null;
              }

              observer.next(tabbyInstance);
              observer.complete();

              if (subscriptions) {
                subscriptions.unsubscribe();
              }
            });

            script.addEventListener('error', (error: unknown) => {
              if (this.addTabbyTokenSubject && !this.addTabbyTokenSubject.closed) {
                this.addTabbyTokenSubject.error(error);
              } else {
                observer.error(error);
              }
              observer.complete();
              this.addTabbyTokenSubject = null;

              if (subscriptions) {
                subscriptions.unsubscribe();
              }
            });

            this.addTabbyTokenSubject = new Subject();
            head.append(script);
          } else {
            observer.error('TABBY_HEAD_NOT_AVAILABLE');
            observer.complete();

            if (subscriptions) {
              subscriptions.unsubscribe();
            }
          }
        }
      });
    } else {
      return of(null);
    }
  }

  private grabTabby(): TabbyInstance | null {
    if (this.akitaRouterQuery.isBrowser) {
      try {
        return (window as any).Tabby;
      } catch {
        return null; // SSR?
      }
    } else {
      return null;
    }
  }

  private getTargetTagDropElement(): HTMLBodyElement | HTMLHeadElement | null {
    if (this.akitaRouterQuery.isBrowser && this.document) {
      const headElement = this.document.querySelectorAll('head');
      if (headElement.length > 0) {
        return headElement[0];
      }
      return this.document.querySelectorAll('body')[0];
    }

    return null;
  }
}
